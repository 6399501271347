/*
* Unobtrusive autocomplete
*
* To use it, you just have to include the HTML attribute autocomplete
* with the autocomplete URL as the value
*
*   Example:
*       <input type="text" data-autocomplete="/url/to/autocomplete">
*
* Optionally, you can use a jQuery selector to specify a field that can
* be updated with the element id whenever you find a matching value
*
*   Example:
*       <input type="text" data-autocomplete="/url/to/autocomplete" data-id-element="#id_field">
*/
$(document).ready(function(){$("input[data-autocomplete]").railsAutocomplete()}),function(t){t.fn.railsAutocomplete=function(){return this.on("focus",function(){this.railsAutoCompleter||(this.railsAutoCompleter=new t.railsAutocomplete(this))})},t.railsAutocomplete=function(t){_e=t,this.init(_e)},t.railsAutocomplete.fn=t.railsAutocomplete.prototype={railsAutocomplete:"0.0.1"},t.railsAutocomplete.fn.extend=t.railsAutocomplete.extend=t.extend,t.railsAutocomplete.fn.extend({init:function(t){function e(e){return e.split(t.delimiter)}function i(t){return e(t).pop().replace(/^\s+/,"")}t.delimiter=$(t).attr("data-delimiter")||null,$(t).autocomplete({source:function(e,a){params={term:i(e.term)},$(t).attr("data-autocomplete-fields")&&$.each($.parseJSON($(t).attr("data-autocomplete-fields")),function(t,e){params[t]=$(e).val()}),$.getJSON($(t).attr("data-autocomplete"),params,function(){0==arguments[0].length&&(arguments[0]=[],arguments[0][0]={id:"",label:"no existing match"}),$(arguments[0]).each(function(e,i){var a={};a[i.id]=i,$(t).data(a)}),a.apply(null,arguments)})},search:function(){if(i(this.value).length<2)return!1},focus:function(){return!1},select:function(i,a){var l=e(this.value);if(l.pop(),l.push(a.item.value),null!=t.delimiter)l.push(""),this.value=l.join(t.delimiter);else if(this.value=l.join(""),$(this).attr("data-id-element")&&$($(this).attr("data-id-element")).val(a.item.id),$(this).attr("data-update-elements")){var n=$(this).data(a.item.id.toString()),r=JSON.parse($(this).attr("data-update-elements"));for(var o in r)$(r[o]).val(n[o])}var u=this.value;return $(this).bind("keyup.clearId",function(){$(this).val().trim()!=u.trim()&&($($(this).attr("data-id-element")).val(""),$(this).unbind("keyup.clearId"))}),$(this).trigger("railsAutocomplete.select",a),!1}})}})}(jQuery);