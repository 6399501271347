// babel polyfills are now in core-js/stable
require('core-js/stable');
require('regenerator-runtime/runtime');
// This allows plugins that are loaded afterward to attach to this jQuery, rather than overwriting
// it with their own
window.$ = window.jQuery = require('jquery');

require('Vendor/jquery.autoresize');
require('Vendor/jquery.iframe-transport.js');
require('Vendor/jquery.remotipart.js');
require('chosen-js');
require('Vendor/modules/jquery-ui-bundle');
require('sparkle');
require('Vendor/spectrum');
require('mediaelement');
require('mediaelement/build/mediaelementplayer.css');
require('Vendor/autocomplete-rails.js');
require('lie/polyfill');
require('Vendor/jquery.ui.touch-punch.min');
require('Vendor/jquery.tablesorter.min.js');
require('Vendor/bootstrap.bundle.min.js'); // dropdown used, includes Popper
require('react-select/dist/react-select.css');
require('react-datepicker/dist/react-datepicker.css');
require('tippy.js/dist/tippy.css');
require('datatables.net')(window, $);
require('datatables.net-plugins/dataRender/datetime')(window, $);
require('datatables.net-buttons-dt')(window, $);
require('datatables.net-buttons/js/buttons.flash')(window, $);
require('datatables.net-buttons/js/buttons.html5')(window, $);
